<template>
  <v-container fill-height fluid class="login-container ma-0">
    <v-row align-content="center" dense>
      <v-col cols="12" sm="12" md="3" lg="3" class="mx-auto">
        <v-card color="rgba(0, 0, 0, 0.4)">
          <v-card-text>
            <v-img :src="require('../assets/IWIS.svg')" class="mb-8"></v-img>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                dark
                autocomplete="off"
                v-model="username"
                :rules="[(v) => !!v || 'UserName required']"
                prepend-icon="mdi-account-circle"
              ></v-text-field>
              <v-text-field
                dark
                @click:append="showPassword = !showPassword"
                autocomplete="off"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :rules="[(v) => !!v || 'PassWord required']"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                prepend-icon="mdi-lock"
                @keydown.enter="submit"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn dark @click="submit" :disabled="loading"> Submit </v-btn>
            <v-btn dark @click="clear" :disabled="loading"> Clear </v-btn>
          </v-card-actions>
        </v-card>
        <v-snackbar
          v-model="snackbar"
          :timeout="snackbar_timeout"
          top
          color="red lighten-2"
        >
          {{ snackbar_text }}
        </v-snackbar>
      </v-col>
      <pswform
        :me="$store.state.me"
        :showform="isPswOpen"
        @close="closePswForm"
        @pwd_changed="pwd_changed"
        :key="kpsw"
      >
      </pswform>
    </v-row>
  </v-container>
</template>

<script>
import { onLogin, onLogout } from "../vue-apollo.js";
import LOGIN from "../graphql/LOGIN.gql";
import LOGOUT from "../graphql/LOGOUT.gql";
import OPTIONS from "../graphql/OPTIONS.gql";
import TEST from "../graphql/TEST.gql";

export default {
  data: () => ({
    showPassword: false,
    isPswOpen: false,
    kpsw: 0,
    valid: true,
    snackbar: false,
    snackbar_text: "",
    snackbar_timeout: 5000,
    username: "",
    password: "",
    loading: false,
    isEndpointAvailable: false,
    userdata: {},
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),
  apollo: {
    // Query with parameters
    test: {
      query: TEST,
      // Additional options here
      fetchPolicy: "network-only",
      //  pollInterval: 1000,
      result({ loading, networkStatus }) {
        if (!loading) this.isEndpointAvailable = networkStatus == 7;
      },
    },
  },
  created() {
    this.$apollo.queries.test;
  },
  components: {
    pswform: () => import("@/components/PasswordForm.vue"),
  },
  computed: {},

  methods: {
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.progress = false;
        });
      return r;
    },
    async logout() {
      let ok = true;
      if (ok) {
        await this.maj(LOGOUT);
        onLogout(this.$apollo.provider.defaultClient);
        this.$router.push("/");
      }
    },

    async submit() {
      let ok = false;
      if (this.$refs.form.validate()) {
        if (this.isEndpointAvailable) {
          this.loading = true;
          let pws = encodeURIComponent(window.btoa(this.password));
          await this.$apollo
            .mutate({
              mutation: LOGIN,
              variables: {
                input: { username: this.username, password: pws },
              },
            })
            .then((data) => {
              //this.snackbar = true;
              if (data.data.login.code == 0 || data.data.login.code == 1) {
                onLogin(
                  this.$apollo.provider.defaultClient,
                  data.data.login.access_token
                );
                ok = true;

                this.userdata = data.data.login.user;
              } else {
                if (data.data.login.code == 2)
                  this.snackbar_text = "Utilisateur Bloqué!";
                // else if (data.data.login.code == 1)
                //   this.snackbar_text = "Utilisateur Déja connecté!";
                this.snackbar = true;
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
              this.snackbar_text = "Error Username or Password!";
              this.snackbar = true;
            });
          if (ok) {
            let r = await this.$requette(OPTIONS, {
              user_id: this.userdata.id,
            });
            if (r.ok) {
              this.userdata = r.data.user;

              // let a = this.userdata.autorisations
              //   ? this.userdata.autorisations.split(",")
              //   : [];
              // let b = "";

              // if (this.userdata.profile)
              //   b = this.userdata.profile.autorisations
              //     ? this.userdata.profile.autorisations.split(",")
              //     : [];
              this.$store.dispatch("LoadOptions", r.data);
              this.$store.dispatch("ActualUser", this.userdata);
              // sessionStorage.setItem(
              //   "isadmin",
              //   this.userdata.profile_id == 1 ? true : false
              // );
              // sessionStorage.removeItem("me");
              // sessionStorage.setItem("me", JSON.stringify(this.userdata));
              // sessionStorage.setItem("auth", JSON.stringify(a.concat(b)));
              // sessionStorage.setItem("options", JSON.stringify(r.data.options));
              // sessionStorage.setItem(
              //   "today",
              //   r.data.getdate[0].today.slice(0, 10)
              // );
              // sessionStorage.setItem(
              //   "structures",
              //   JSON.stringify(r.data.structures)
              // );
              if (this.userdata.mpc == "1") {
                this.isPswOpen = true;
                this.kpsw++;
              } else {
                //this.$apollo.queries.test.stopPolling();
                this.$router.push("/dashboard");
              }
            }
          }
        } else {
          this.snackbar_text = "Network error!";
          this.snackbar_color = "error";
          this.snackbar = true;
        }
      }
    },
    async closePswForm() {
      this.isPswOpen = false;
      await this.logout();
    },
    pwd_changed() {
      this.isPswOpen = false;
      // this.$apollo.queries.test.stopPolling();
      this.$router.push("/dashboard");
    },
    clear() {
      this.password = "";
      this.username = "";
    },
  },
};
</script>
<style scoped>
.login-container {
  background: url("../assets/well24.jpg");
  background-size: cover;

  background-repeat: no-repeat;
}
</style>
